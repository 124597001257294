var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"section-title my-3"},[_vm._v(" The changes you make in that block don't need to be reviewed. Changes will be applied immediately. ")]),_c('b-alert',{staticClass:"info-alert",attrs:{"variant":"info","show":""}},[_c('div',{staticClass:"info-alert__title"},[_c('span',{staticClass:"material-symbols-rounded"},[_vm._v(" info ")]),_vm._v(" Price Calculations ")]),_c('ul',[_c('li',[_c('b',[_vm._v("One-Way Trip:")]),_vm._v(" Base price + $/Mile + Additional Services + Overtime - Service Fee ")]),_c('li',[_c('b',[_vm._v("Round-Trip:")]),_vm._v(" Base price + ($/Mile x 2) + Additional Services + Overtime - Service Fee ")])])]),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":_vm.msg.type,"dismissible":"","show":_vm.msg.text},model:{value:(_vm.msg.has),callback:function ($$v) {_vm.$set(_vm.msg, "has", $$v)},expression:"msg.has"}},[_vm._v(_vm._s(_vm.msg.text))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-xl-5 mb-3 mb-md-0 h-100"},[_c('h3',{staticClass:"header-title"},[_vm._v("Car types")]),_c('div',{staticClass:"card h-100 mb-0"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},_vm._l((_vm.form.cartypes),function(cartype,i){return _c('div',{key:cartype.key,staticClass:"col-12 col-xl-6",class:{ 'mb-2 mb-xl-0': i !== _vm.form.cartypes.length - 1 }},[_c('div',{staticClass:"form-group mb-2"},[_c('div',{staticClass:"input-group"},[_c('b-form-checkbox',{attrs:{"value":true,"unchecked-value":false},on:{"change":function($event){cartype.checked
                        ? (cartype.value = '')
                        : (cartype.value = 0)},"input":function($event){return _vm.moveToNextField($event, `cartype-${cartype.key}`)}},model:{value:(cartype.checked),callback:function ($$v) {_vm.$set(cartype, "checked", $$v)},expression:"cartype.checked"}},[_vm._v(" "+_vm._s(cartype.label)+" ")])],1)]),_c('div',{staticClass:"form-group my-2"},[_c('label',{attrs:{"for":`cartype-${cartype.key}`}},[_vm._v("Price per mile")]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(cartype.value),expression:"cartype.value",modifiers:{"number":true}},{name:"mask",rawName:"v-mask",value:(_vm.currencyMask),expression:"currencyMask"}],ref:`cartype-${cartype.key}`,refInFor:true,staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.submitted && _vm.$v.form.cartypes.$each[i].value.$error,
                    },attrs:{"placeholder":"$","type":"text","id":`cartype-${cartype.key}`,"disabled":!cartype.checked},domProps:{"value":(cartype.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(cartype, "value", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"form-group my-0"},[_c('label',{attrs:{"for":`cartype-baseprice-${cartype.key}`}},[_vm._v("Base price")]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(cartype.baseprice),expression:"cartype.baseprice",modifiers:{"number":true}},{name:"mask",rawName:"v-mask",value:(_vm.currencyMask),expression:"currencyMask"}],ref:`cartype-baseprice-${cartype.key}`,refInFor:true,staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.submitted &&
                        _vm.$v.form.cartypes.$each[i].baseprice.$error,
                    },attrs:{"placeholder":"$","type":"text","id":`cartype-baseprice-${cartype.key}`,"disabled":!cartype.checked},domProps:{"value":(cartype.baseprice)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(cartype, "baseprice", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      _vm.submitted && _vm.$v.form.cartypes.$each[i].baseprice.$error
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Min 0.5 or off (unchecked) ")]):_vm._e()])])])}),0)])])]),_c('div',{staticClass:"col-md-6 col-xl-7 mb-3 mb-md-0 h-100"},[_c('h3',{staticClass:"header-title"},[_vm._v("Additional services")]),_c('div',{staticClass:"card h-100 mb-0"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},_vm._l((_vm.form.services),function(service,i){return _c('div',{key:service.c,staticClass:"col-12 col-xl-4",class:{ 'mb-2 mb-xl-0': i !== _vm.form.services.length - 1 }},[_c('div',{staticClass:"form-group mb-2"},[_c('div',{staticClass:"input-group"},[_c('b-form-checkbox',{attrs:{"value":true,"unchecked-value":false},on:{"change":function($event){service.checked
                        ? (service.value = '')
                        : (service.value = null)},"input":function($event){return _vm.moveToNextField($event, `service-${service.c}`)}},model:{value:(service.checked),callback:function ($$v) {_vm.$set(service, "checked", $$v)},expression:"service.checked"}},[_vm._v(" "+_vm._s(service.t)+" ")])],1)]),_c('div',{staticClass:"form-group my-0"},[_c('label',{attrs:{"for":`service-${service.c}`}},[_vm._v("Price")]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(service.value),expression:"service.value",modifiers:{"number":true}},{name:"mask",rawName:"v-mask",value:(_vm.currencyMask),expression:"currencyMask"}],ref:`service-${service.c}`,refInFor:true,staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.submitted && _vm.$v.form.services.$each[i].value.$error,
                    },attrs:{"placeholder":"$","type":"text","id":`service-${service.c}`,"disabled":!service.checked},domProps:{"value":(service.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(service, "value", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])])])}),0)])])])]),_c('div',{staticClass:"row my-0 mt-md-3"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"header-title"},[_vm._v("After hours additional cost (optional)")]),_c('div',{staticClass:"card mb-0"},[_c('div',{staticClass:"card-body"},[(!_vm.form.overtimes.length)?_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn btn-outline-primary mx-auto",attrs:{"title":"Add overtime","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addOvertime.apply(null, arguments)}}},[_c('span',{staticClass:"material-symbols-rounded"},[_vm._v(" add_location ")]),_vm._v(" Add overtime ")])]):_c('div',{staticClass:"overtime-slider"},_vm._l((_vm.form.overtimes),function(overtime,i){return _c('div',{key:overtime.id,staticClass:"row",class:{ 'mb-3': i < _vm.form.overtimes.length - 1 }},[_c('div',{staticClass:"col-12 col-xl-9"},[_c('div',{staticClass:"row"},[_vm._m(0,true),_c('div',{staticClass:"col-auto ml-auto text-center"},[_c('div',{staticClass:"invalid-feedback text-center",class:{
                        'is-invalid slider-diapason-msg d-block':
                          _vm.$v.form.overtimes.$each[i].slider.$error,
                      }},[_vm._v(" Overtime diapason "),_c('b',[_vm._v("["+_vm._s(_vm.getOverflowTime(overtime.slider[0])?.name)+" - "+_vm._s(_vm.getOverflowTime(overtime.slider[1])?.name)+"]")]),_vm._v(" has collision ")])]),_vm._m(1,true)]),_c('vue-slider',{class:{
                    'is-invalid': _vm.$v.form.overtimes.$each[i].slider.$error,
                  },attrs:{"data":_vm.sliderSteps,"data-value":'id',"data-label":'name',"min-range":1,"max-range":25},scopedSlots:_vm._u([{key:"step",fn:function({ label, active }){return [_c('div',{class:['custom-step', { active }]})]}}],null,true),model:{value:(overtime.slider),callback:function ($$v) {_vm.$set(overtime, "slider", $$v)},expression:"overtime.slider"}})],1),_c('div',{staticClass:"col-auto mx-auto col-xl-3"},[_c('div',{staticClass:"form-group mb-0"},[_c('label',[_vm._v("Price")]),_c('div',{staticClass:"input-group input-group-merge input-with-control-ico"},[_c('div',{staticClass:"position-relative w-100"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(overtime.price),expression:"overtime.price",modifiers:{"number":true}},{name:"mask",rawName:"v-mask",value:(_vm.currencyMask),expression:"currencyMask"}],staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitted &&
                            _vm.$v.form.overtimes.$each[i].price.$error,
                        },attrs:{"placeholder":"$","type":"text"},domProps:{"value":(overtime.price)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(overtime, "price", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]),(_vm.form.overtimes.length < _vm.sliderSteps.length - 1)?_c('a',{staticClass:"btn btn-outline-primary btn-ico ml-2",attrs:{"title":"Add overtime","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addOvertime.apply(null, arguments)}}},[_c('span',{staticClass:"material-symbols-rounded"},[_vm._v(" add_location ")])]):_vm._e(),_c('a',{staticClass:"btn btn-transparent btn-ico btn-ico-remove ml-1",attrs:{"title":"Remove location","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeOvertime(overtime.id)}}},[_c('span',{staticClass:"material-symbols-rounded"},[_vm._v(" cancel ")])])])])])])}),0)])])])]),_c('div',{staticClass:"text-center text-md-right my-3"},[_c('button',{staticClass:"btn btn-outline-secondary mr-2",attrs:{"disabled":_vm.trySubmit},on:{"click":function($event){$event.preventDefault();return _vm.handleReset.apply(null, arguments)}}},[_vm._v(" Reset ")]),_c('button',{staticClass:"btn btn-outline-primary mw-230",attrs:{"disabled":_vm.trySubmit},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(" Save changes ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('label',[_vm._v(" Period start ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto ml-auto"},[_c('label',[_vm._v("Period end")])])
}]

export { render, staticRenderFns }