var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"section-title my-3"},[_vm._v(" The changes you make in that block don't need to be reviewed. Changes will be applied immediately. ")]),_c('div',{staticClass:"row my-0 mt-md-3"},[_c('div',{staticClass:"col-sm-12 col-xl-4"},[_c('h3',{staticClass:"header-title"},[_vm._v("Change Password")]),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":_vm.msg.type,"dismissible":"","show":_vm.msg.text},model:{value:(_vm.msg.has),callback:function ($$v) {_vm.$set(_vm.msg, "has", $$v)},expression:"msg.has"}},[_vm._v(_vm._s(_vm.msg.text))]),_c('div',{staticClass:"card mb-0"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group mb-2"},[_c('label',{attrs:{"for":"vendor-password"}},[_vm._v("Old Password")]),_c('div',{staticClass:"input-group input-group-merge"},[((_vm.showPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
                },attrs:{"id":"vendor-password","placeholder":"Enter old password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.password)?_vm._i(_vm.form.password,null)>-1:(_vm.form.password)},on:{"change":function($event){var $$a=_vm.form.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "password", $$c)}}}}):((_vm.showPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
                },attrs:{"id":"vendor-password","placeholder":"Enter old password","type":"radio"},domProps:{"checked":_vm._q(_vm.form.password,null)},on:{"change":function($event){return _vm.$set(_vm.form, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
                },attrs:{"id":"vendor-password","placeholder":"Enter old password","type":_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('div',{staticClass:"input-group-append",attrs:{"role":"button"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('div',{staticClass:"input-group-text"},[(_vm.showPassword)?_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility")]):_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility_off")])])]),(_vm.submitted && _vm.$v.form.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.password.required)?_c('div',[_vm._v(" Old Password is required ")]):_vm._e(),(!_vm.$v.form.password.minLength)?_c('div',[_vm._v("Passwords min 8")]):_vm._e(),(!_vm.$v.form.password.maxLength)?_c('div',[_vm._v("Passwords max 16")]):_vm._e(),(!_vm.$v.form.password.valid)?_c('div',[_vm._v(" Passwords is not valid, must include: A-Z, a-z, 0-9, #?!@$%^&*- ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-2"},[_c('label',{attrs:{"for":"vendor-newpassword"}},[_vm._v("New Password")]),_c('div',{staticClass:"input-group input-group-merge"},[((_vm.showNewpassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newpassword),expression:"form.newpassword"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.newpassword.$error,
                },attrs:{"id":"vendor-newpassword","placeholder":"Enter New Password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.newpassword)?_vm._i(_vm.form.newpassword,null)>-1:(_vm.form.newpassword)},on:{"change":function($event){var $$a=_vm.form.newpassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "newpassword", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "newpassword", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "newpassword", $$c)}}}}):((_vm.showNewpassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newpassword),expression:"form.newpassword"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.newpassword.$error,
                },attrs:{"id":"vendor-newpassword","placeholder":"Enter New Password","type":"radio"},domProps:{"checked":_vm._q(_vm.form.newpassword,null)},on:{"change":function($event){return _vm.$set(_vm.form, "newpassword", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newpassword),expression:"form.newpassword"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.newpassword.$error,
                },attrs:{"id":"vendor-newpassword","placeholder":"Enter New Password","type":_vm.showNewpassword ? 'text' : 'password'},domProps:{"value":(_vm.form.newpassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "newpassword", $event.target.value)}}}),_c('div',{staticClass:"input-group-append",attrs:{"role":"button"},on:{"click":function($event){_vm.showNewpassword = !_vm.showNewpassword}}},[_c('div',{staticClass:"input-group-text"},[(_vm.showNewpassword)?_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility")]):_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility_off")])])]),(_vm.submitted && _vm.$v.form.newpassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.newpassword.required)?_c('div',[_vm._v(" New Password is required ")]):_vm._e(),(!_vm.$v.form.newpassword.minLength)?_c('div',[_vm._v(" Passwords min 8 ")]):_vm._e(),(!_vm.$v.form.newpassword.maxLength)?_c('div',[_vm._v(" Passwords max 16 ")]):_vm._e(),(!_vm.$v.form.newpassword.valid)?_c('div',[_vm._v(" Passwords is not valid, must include: A-Z, a-z, 0-9, #?!@$%^&*- ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"vendor-renewpassword"}},[_vm._v("Confirm New Password")]),_c('div',{staticClass:"input-group input-group-merge"},[((_vm.showRenewpassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.renewpassword),expression:"form.renewpassword"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.renewpassword.$error,
                },attrs:{"id":"vendor-renewpassword","placeholder":"Confirm New Password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.renewpassword)?_vm._i(_vm.form.renewpassword,null)>-1:(_vm.form.renewpassword)},on:{"change":function($event){var $$a=_vm.form.renewpassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "renewpassword", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "renewpassword", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "renewpassword", $$c)}}}}):((_vm.showRenewpassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.renewpassword),expression:"form.renewpassword"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.renewpassword.$error,
                },attrs:{"id":"vendor-renewpassword","placeholder":"Confirm New Password","type":"radio"},domProps:{"checked":_vm._q(_vm.form.renewpassword,null)},on:{"change":function($event){return _vm.$set(_vm.form, "renewpassword", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.renewpassword),expression:"form.renewpassword"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.renewpassword.$error,
                },attrs:{"id":"vendor-renewpassword","placeholder":"Confirm New Password","type":_vm.showRenewpassword ? 'text' : 'password'},domProps:{"value":(_vm.form.renewpassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "renewpassword", $event.target.value)}}}),_c('div',{staticClass:"input-group-append",attrs:{"role":"button"},on:{"click":function($event){_vm.showRenewpassword = !_vm.showRenewpassword}}},[_c('div',{staticClass:"input-group-text"},[(_vm.showRenewpassword)?_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility")]):_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility_off")])])]),(_vm.submitted && _vm.$v.form.renewpassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.renewpassword.sameAsPassword)?_c('div',[_vm._v(" Passwords are not matched ")]):_vm._e()]):_vm._e()])])])])],1)]),_c('div',{staticClass:"text-center text-md-left my-3"},[_c('button',{staticClass:"btn btn-outline-primary mw-230",attrs:{"disabled":_vm.trySubmit},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(" Change password ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }